import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const ErinHollandStoryPage = () => (

    <Layout pageClass="story-page">
    <SEO title="Pete Aalbers - Hardcase Container Hire Ltd - Story" />
    <main className="main">
        <div className="typography inner">
            <h1>Peter Aalbers - Hardcase Container Hire Ltd</h1>          
            <p>My company, Hardcase Container Hire Ltd, was languishing in a slow growth mode in the Covid-19 year, and soon to run short of container stock. I needed funding. The bank had always been reluctant for funding in the past. I called out to Paul McCoy of Experience on Tap for coaching and advice. He gave me insights that then became a watershed moment for me and my company.</p>

           <p>Under Paul’s tutelage, I composed a business plan, a cashflow forecast, I crafted (like a work of art) two large loan applications to the bank that were approved straight away, and an overview of the company that is now skyrocketing in sales in all four areas of our work.

Business is thriving. I am excited, ecstatic even. And that excitement flows down through my staff (like a virus – infectious). They have been impelled to greater performance- more business, more busyness, more quotes and invoices, more bill paying, more cash in the cheque account.</p>

            <p>From the coaching from Experience on Tap, my company is booming, and I am bursting to get back to work each day. Wife is happy. Life couldn’t get better.</p>
        </div>
    </main>
  </Layout>

)


export default ErinHollandStoryPage